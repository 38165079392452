<template>
    <div>
        <component
            v-bind:is="componentName"
            :pageData="pageData"
            v-if="componentName"
        />
        <section class="contact" v-else-if="!componentName">
            <div class="container">
                <div class="row">
                    <div
                        class="col-md-12"
                        v-html="pageData.content"
                        style="color: white !important;"
                    ></div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
    name: "Static",
    data() {
        return {
            pageData: {},
            isNotFound: false
        };
    },
    watch: {
        $route: {
            handler: function() {
                this.getPage();
            },
            deep: true
        }
    },
    created() {
        this.getPage();
    },
    computed: {
        componentName: function() {
            if (this.pageData && this.pageData.component_name) {
                return this.pageData.component_name.replace(".vue", "");
            }
            return null;
        },
        pageLanguage: function() {
            return this.$store.state.defaultStore.pageLanguage;
        }
    },
    methods: {
        getPage() {
            const self = this;
            let rt = self.$route.path.trim("//");
            ApiService.get("/page" + (rt !== "/" ? rt : ""))
                .then(response => {
                    self.pageData = response.data.data;
                    // self.metaCreation(self.pageData);
                })
                .catch(err => {
                    console.log(err);
                    self.$router.push({ name: "NotFound" });
                });
        }
    }
};
</script>

<style scoped></style>
